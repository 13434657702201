// Customizable Area Start
import React from "react";
import ChooseFromMapController, { Props } from "./ChooseFromMapController.web";
import Maps from "../../../maps/src/Maps.web";
import {
    FormLabel, FormControl, TextField, Typography, styled,
    Box, Button
} from '@mui/material';
import { backbutton, choosefromap, search3 } from "../assets";
import { configJSON } from "../ProjectTemplatesController";


// Customizable Area End

// Customizable Area Start
const MyFormLabel = styled(FormLabel)({
    color: '#334155',
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "5px",
    lineHeight: "22px"
});
const Input = styled(TextField)({
    marginBottom: '16px',
    position: "relative",
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        backgroundColor: "#ffffff",
        paddingLeft: "50px"
    },
});

const Choosemapmaincontainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "space-around",
    paddingTop: "30px",
    "@media (max-width: 1300px)": {
    },
    "@media (max-width: 800px)": {
        flexDirection: "column"
    },
});

const Mapcontainer = styled(Box)({
    height: "700px",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    position: "relative",
    "@media (max-width: 1250px)": {
        width: "45%"
    },
    "@media (max-width: 1100px)": {
        width: "50%"
    },
    "@media (max-width: 800px)": {
        width: "100%"
    }
});

const SearchContainer = styled(Box)({
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    width: "50%",
    "@media (max-width: 1000px)": {
        alignItems: 'start',
        paddingTop: "100px"
    },
    "@media (max-width: 1100px)": {
        width: "35%"
    },
    "@media (max-width: 800px)": {
        width: "100%"
    }
});

const Pickmebuttonbox = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 800px)": {
        paddingBottom: "30px"
    }
});


const Pickmebutton = styled(Box)({
    width: "70%",
    display: "flex",
    justifyContent: "center",
    background: "#1D6FB8",
    borderRadius: "16px",
    height: "52px",
    "@media (max-width: 950px)": {
        width: "100%"
    },

});


// Customizable Area End

export default class SearchLocation extends ChooseFromMapController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webstyle.mainbox}>
                <Choosemapmaincontainer>
                    <Mapcontainer >
                        <Typography style={webstyle.choosemaptext}>{configJSON.ChooseFromMap}</Typography>
                        <Maps navigation={undefined} id={""} />
                        <Box onClick={this.props.backTofirstpage} style={{
                            position: "absolute",
                            top: "90px",
                            left: "31px",
                            background: "white",
                            borderRadius: "95%",
                            width: "33px",
                            height: "36px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img src={backbutton.default} />
                        </Box>

                    </Mapcontainer>
                    <SearchContainer>
                        <Box>
                            <Box>
                                <img src={choosefromap.default} style={webstyle.image} />
                            </Box>
                            <Box>

                                <FormControl fullWidth>
                                    <MyFormLabel>{configJSON.pickup}</MyFormLabel>
                                    <Input fullWidth variant="outlined"
                                        placeholder="City,street or station"
                                    />

                                    <img src={search3.default} style={webstyle.searchimage} />
                                </FormControl>
                            </Box>
                            <Pickmebuttonbox >
                                <Pickmebutton>
                                    <Button style={webstyle.buttontext}>{configJSON.pickme}</Button>
                                </Pickmebutton>
                            </Pickmebuttonbox>
                        </Box>

                    </SearchContainer>

                </Choosemapmaincontainer>

            </Box >

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webstyle = {
    choosemaptext: {
        fontWeight: 700,
        fontSize: '16px',
        color: "#0F172A"
    },

    searchimage: {
        position: "absolute" as 'absolute',
        top: "44px",
        left: "20px"
    },

    buttontext: {
        color: "white",
        textTransform: "capitalize" as 'capitalize',
        fontWeight: 700,
        fontSize: '16px'
    },

    mainbox: {
        display: "flex",
        justifyContent: "center"
    },

    image: {
        width: "100%"
    }
}
// Customizable Area End