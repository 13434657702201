
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    onDataReceived:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    upcomingridesdata:any
    openModalIndex:number | undefined,
    openEditDialog:boolean
    anchorEl2:any;
    rideId:any;
    deletePopUp:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UpcomingRidesController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    getupcomingridesApiCallId:any;
    DeleteRideApiCallid:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            openModalIndex:undefined,
            upcomingridesdata:[
            ],
            openEditDialog:false,
            anchorEl2:null,
            rideId:null,
            deletePopUp:false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const token = localStorage.getItem("token")
        if (token){
            this.getUpcomingRides(token)
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            runEngine.debugLog("API Message Recived", message);
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
              apiRequestCallId === this.getupcomingridesApiCallId
            ) {
              if (responseJson && responseJson.data) {
                const statuses = ["In Progress","Scheduled",];
                const updatedData = responseJson.data.map((item:any, index:number) => ({
                  ...item, 
                  attributes: {
                    ...item.attributes,
                  },
                }));
                this.setState({ upcomingridesdata: updatedData })
              }
      
            }
            if (
              apiRequestCallId === this.DeleteRideApiCallid
            ) {
              this.handleDeleteRideResponse(responseJson);
            }
      
          }
        // Customizable Area End
    }

    // Customizable Area Start
  handleDeleteRideResponse(responseJson: any) {
    if (responseJson && responseJson.meta.message === "Ride cancelled successfully") {
      this.setState({ deletePopUp: false });
      const token = localStorage.getItem("token");
      if (token) {
        this.getUpcomingRides(token)
      }

    }
  }
  navigateToRating = (status: string, id: any, e: any) => {
    if (status == "completed") {
      const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
      navigate.addData(getName(MessageEnum.NavigationTargetMessage), "Reviews");
      navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigate);
    }
    if (status == "in_progress") {
      e.stopPropagation();
      this.setState({ openEditDialog: true, anchorEl2: e.currentTarget })
      this.setState({ rideId: id })
    }
    if (status == "scheduled") {
      e.stopPropagation();
      this.setState({ openEditDialog: true, anchorEl2: e.currentTarget })
      this.setState({ rideId: id })

    }
  }
  onCloseDeletePopup = (e: any) => {
    e?.stopPropagation();
    this.setState({ deletePopUp: false });
    this.setState({ rideId: null });
  }
  onDelete = () => {
    const token = localStorage.getItem("token")
    const header = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("status", "cancelled");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteRideApiCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteapiendpoint}${this.state.rideId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  onEdit = (e: any) => {
    e.stopPropagation();
    const { rideId } = this.state;
    const fromTripHistoryScreen = true;
    this.props.onDataReceived({ rideId, fromTripHistoryScreen });
  }
  onCancel = (e: any) => {
    e.stopPropagation();
    this.setState({ openEditDialog: false });
    this.setState({ deletePopUp: true });

  }
  onClose = (e: any) => {
    e.stopPropagation();
    this.setState({ openEditDialog: false });
    this.setState({ rideId: null });
  }
  capitalizeFirstLetter(status: string): string {
    if (!status) return '';
    if (status === 'in_progress') {
      return 'In Progress';
    }

    return status.charAt(0).toUpperCase() + status.slice(1);
  }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
    
        return formattedDate;
      }

      getStatusStyles = (status: any) => {
        switch (status) {
          case "in_progress":
            return {
              backgroundColor: "#CBB09F",
    color: "#AE5F05"
            };
            case "accepted":
              return {
                backgroundColor: "#D1FAE5",
                color: "#059669"
              };
              case "pending":
                return {
                  backgroundColor: "#FEF3C7",
                  color: "#D97706"
                };
          case "scheduled":
            return {
               backgroundColor: "#DBEAFE",
              color: "#2563EB"
            };
          case "completed":
            return {
              backgroundColor: "#D1F7C4",  // Light green
              color: "#006400"  // Dark green
            };
          case "cancelled":
            return {
              backgroundColor: "pink",  // Light green
              color: "red"  // Dark green
            };
          default:
            return {
              backgroundColor: "#FFFFFF",  // Default to white if status is unknown
              color: "#000000"  // Default to black text
            };
        }
      };
    
      formatTime = (timeString: string): string => {
        try {
          const [, timePart] = timeString.split('T');
          let [hours, minutes] = timePart.split(':');
          let numericHours = parseInt(hours, 10);
          let numericMinutes = parseInt(minutes, 10);
    
          const period = numericHours >= 12 ? 'PM' : 'AM';
          const adjustedHours = numericHours % 12 || 12;
          const adjustedMinutes = numericMinutes.toString().padStart(2, '0');
    
          return `${adjustedHours}:${adjustedMinutes} ${period}`;
        } catch (error) {
          return 'Invalid time';
        }
      };

    getUpcomingRides = (token: string) => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getupcomingridesApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getupcomingrideendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End
}
