Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.examplePatchAPiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "bx_block_projecttemplates/project_templates";
exports.schedulerideapi = "bx_block_location/locations";
exports.updaterideapi = "bx_block_tasks/task_lists/scheduled_rides/"
exports.getupcomingrideendpoint = "bx_block_tasks/task_lists/scheduled_rides"
exports.ordersummaryapi= "bx_block_tasks/task_lists/order_summary/"
exports.pickuptext = "Pickup time"
exports.btnExampleTitle = "CLICK ME";
exports.Start = "Start";
exports.Destination = "Destination";
exports.FavoritePlaces = "Favorite Places";
exports.ChooseFromMap = "Choose from Map";
exports.FrequentlyUsed = "Frequently Used";
exports.CarType = "Car Type";
exports.sucessMessage = "Your ride is all set!";
exports.Sucessdescription = "Your booking has been confirmed.";
exports.Sucessdescription2 = "Driver will pickup you in 2 minutes.";
exports.LocationError = "Sorry! We don't cover this location right now.";
exports.ErrorText2 = "Please check your internet connection";
exports.from = "From:"
exports.to = "To:";
exports.price = "Price:";
exports.edit = 'Edit';
exports.cancel = "Cancel";
exports.pickup = "Set Pickup location on map"
exports.pickme = "Pick me from here"
exports.done = "Done"
exports.save = "Save"
exports.continue = 'Continue'
exports.error = 'Start and Destination is required'
exports.cartyperror = "Car Type is required"
exports.destination = 'Destination is required'
exports.startdestination = "Start location is required"
exports.CarAPiEndPoint = '/bx_block_location/car_types'
exports.deleteapiendpoint = "/bx_block_tasks/task_lists/cancel_ride/";
exports.patchApiMethod = "PATCH";
exports.contactUsApiContentType = "application/json";
exports.getuserprofilendpoint2 = "/bx_block_profile/profiles/user_details";
exports.httpGetMethod = "GET";
// Customizable Area End