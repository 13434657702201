import React from "react";

// Customizable Area Start
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
    Snackbar,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { cameraImage, photoFace,doller,profile } from "./assets";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from './NavigationMenu';
import Setting from "../../settings2/src/Settings2.web";
import SideMenuController, {
    configJSON,
} from "./SideMenuController.web";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HelpCentre from "../../helpcentre/src/HelpCentre.web";
import UserProfile from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import FavLocation from "../../location/src/FavLocation.web";
import WalletScreen from "../../stripepayments/src/WalletScreen.web";
import PaymentScreen from "../../stripepayments/src/PaymentScreen.web";
import PaymentMethod from "../../stripepayments/src/PaymentMethod.web";
import AppointmentRider from "../../appointmentmanagement/src/AppointmentRider.web";
import NotificationUIDriver from "../../notifications/src/NotificationUIDriver.web";
import NotificationUIRider from "../../notifications/src/NotificationUIRider.web";
import TripHistory from "../../tasks/src/TripHistory.web";
// Customizable Area End

export default class SideMenu extends SideMenuController {

    // Customizable Area Start
    renderRightSection = () => {
        const userloggedin = localStorage.getItem("role_id");
        switch (this.state.selectedSection) {
            case "appointment":
                return <AppointmentRider navigation={undefined} id={""} />;
            case `${configJSON.editProfile}`:
                return <UserProfile navigation={this.props.navigation} id={""} uploadImageState={this.state.uploadImageState} uploadImageFunction={this.handleUploadImage}  handleFileChange={this.handleFileChange} imagefile={this.state.imagefile} updateProfile={this.updateResponse} removePhoto={this.removeUrl}/>;
            case `${configJSON.payments}`:
                if (this.state.paymentDetails) {
                    return (
                      <PaymentScreen
                      navigation={this.props.navigation}
                      id={""}
                      onSave={this.handleShowPayment} // Go back to WalletScreen
                      />
                    );
                  }
            
                  if (this.state.showDetails) {
                    return (
                    <PaymentMethod
                    navigation={this.props.navigation}
                    id={""}
                    onSave={this.handleShowPayment}/>
                    );
                  }
            
                  return (
                    <WalletScreen
                      navigation={this.props.navigation}
                      id={""}
                      onDetailsClick={this.handleShowDetails}
                      onPaymentDetailsClick={this.handleShowPaymentDetails} 
                      selectedSection={this.state.selectedSection}
                      setSelectedSection={this.setSelectedSection}
                    />
                  );
                
            case `${configJSON.favLocation}`:
                return <FavLocation navigation={undefined} id={""} />;
                case `${configJSON.notifications}`:
                    if (userloggedin === "driver") {
                        return (
                            <NotificationUIDriver
                                selectedSection={this.state.selectedSection}
                                setSelectedSection={this.setSelectedSection}
                                navigation={this.props.navigation}
                                id={""}
                            />
                        );
                    } else if (userloggedin === "rider") {
                        return (
                            <NotificationUIRider
                                selectedSection={this.state.selectedSection}
                                setSelectedSection={this.setSelectedSection}
                                navigation={this.props.navigation}
                                id={""}
                            />
                        );
                    }
                    break;
            case `${configJSON.helpCenter}`:
                return <div><HelpCentre navigation={this.props.navigation} id={""} /></div>;
            case `${configJSON.settings}`:
                return <Setting navigation={this.props.navigation} id={""} redirectedit={this.personalinfo}/>;
                case `${configJSON.tripHistory}`:
                    return <TripHistory navigation={this.props.navigation} id={""} />;
        }
    };
    handleShowDetails = () => {
        this.setState({ showDetails: true, paymentDetails: false });
      };
    
      handleShowPayment = () => {
        this.setState({ showDetails: false, paymentDetails: false });
      };
    
      handleShowPaymentDetails = () => {
        this.setState({ showDetails: false, paymentDetails: true });
      };
    handleData = () => {
        const driverMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.payments}`, icon: <CreditCardOutlinedIcon /> },
            { text: `${configJSON.notifications}`, icon: <NotificationsNoneIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        const riderMenuItems = [
            { text: `${configJSON.editProfile}`, icon: <PermIdentityIcon /> },
            { text: `${configJSON.tripHistory}`, icon: <DateRangeIcon /> },
            { text: `${configJSON.favLocation}`, icon: <StarBorderIcon /> },
            { text: `${configJSON.notifications}`, icon: <NotificationsNoneIcon /> },
            { text: `${configJSON.helpCenter}`, icon: <HelpOutlineIcon /> },
            { text: `${configJSON.settings}`, icon: <SettingsOutlinedIcon /> },
        ];

        let data = this.state.idUserRole === "driver" ? driverMenuItems : riderMenuItems;
        return data;
    }

    profileContainer = () =>{
        return (
            <>
              <ProfileContainer data-testId={"visibleImageModalID"} onClick={this.handleUploadImage}>
                        <FaceIcon src={!this.state.imageUrl ? profile.default : this.state.imageUrl} alt={configJSON.camera} />
                            <TypographyEdit>{!this.state.imageUrl && configJSON.editProfile}</TypographyEdit>
                        </ProfileContainer>
                        <TypographyName>{this.state.fullname}</TypographyName>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id={""} index={-1} imageurl={this.state.imageUrl}/>
                {
                this.state.selectedSection !== `${configJSON.settings}` &&this.state.selectedSection !== `Payments`&& this.state.selectedSection !== `${configJSON.notifications}` && this.state.selectedSection !== "appointment" &&<Header>
                {this.profileContainer()}
                </Header>
            }
                {
                    this.state.selectedSection === "Payments" && <Header2>
                        <WalletSection>
                            <Typography style={{ color: "#0F172A", fontSize: '26px', fontWeight: 700 }}>
                                My Wallet
                            </Typography>
                            <WalletInfo>
                                <DollarImage src={doller.default} alt="Dollar" />
                                <Typography style={{ color: "#0F172A", fontSize: '20px', fontWeight: 700 }}>
                                    25000
                                </Typography>
                            </WalletInfo>
                        </WalletSection>
                        <ProfileImage src="https://randomuser.me/api/portraits/men/75.jpg" alt="Profile" />
                    </Header2>
                }
                <IconButtonForToogle onClick={this.handleToggleSidebar} style={{ display: window.innerWidth <= 960 ? `${configJSON.block}` : `${configJSON.none}` }}>
                    <MenuIcon />
                </IconButtonForToogle>

            <FullContainer sx={{ marginTop: (this.state.selectedSection == `${configJSON.settings}` || this.state.selectedSection == "appointment") ? `${configJSON.px40}` : `${configJSON.px0}` }}>
                {/** Hide Sidebar when notifications section is selected */}
                {
                    this.state.selectedSection !== `${configJSON.notifications}` &&
                    (this.state.selectedSection !== 'Payments' ||
                      (this.state.paymentDetails === true && !this.state.showDetails)) &&    (
                        <Sidebar
                            style={{
                                display: window.innerWidth > 960 || this.state.isSidebarOpen
                                    ? `${configJSON.block}`
                                    : `${configJSON.none}`,
                                    backgroundColor: '#ECFDF5', 
                                    position: 'relative', 
                                    borderTopLeftRadius: '15px',
                                    borderTopRightRadius: '15px', 
                                    borderBottomLeftRadius: '10px', 
                                    borderBottomRightRadius: '10px', 
                            }}
                        >
                            <List component="nav" data-testId={"featureBtnId"}
                              style={{
                                backgroundColor: 'white', // Set the List background color to white
                                borderTopLeftRadius: '15px', // Round top corners of the list items area
                                borderTopRightRadius: '15px', // Round top corners of the list items area
                                borderBottomLeftRadius: '15px', // Bottom-left border radius
                                borderBottomRightRadius: '15px', // Bottom-right border radius
                                paddingBottom:'30px'
                            }}>
                                {this.handleData().map((item: any) => (
                                    <ListItemLeft
                                        key={item.text}
                                        onClick={() => this.handleSectionChange(item.text)}
                                    >
                                        <ListItemIconSideBar>
                                            <StyledIcon style={{
                                                color: this.state.selectedSection === item.text
                                                    ? `${configJSON.color1}`
                                                    : `${configJSON.color2}`,
                                            }}>
                                                {item.icon}
                                            </StyledIcon>
                                        </ListItemIconSideBar>
                                        <ListItemText
                                            primary={item.text}
                                            style={{
                                                color:
                                                    this.state.selectedSection === item.text
                                                        ? `${configJSON.color1}`
                                                        : `${configJSON.color2}`,
                                            }}
                                        />
                                    </ListItemLeft>
                                ))}
                            </List>
                        </Sidebar>
                    )
                }
                <BoxList>{this.renderRightSection()}</BoxList>
                <Snackbar
            open={this.state.istoastopen2}
            autoHideDuration={5000}
            onClose={this.handleClosetoast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert severity={"error"} onClose={this.handleClosetoast}
              sx={{ backgroundColor:  "rgb(208, 52, 52)", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
             Invalid image type. Only JPG, JPEG, and PNG are allowed.
            </MuiAlert>
          </Snackbar>
            </FullContainer>
        </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const FullContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
        flexDirection: "column",
        marginTop: "30px",
    }
});

const BoxList = styled(Box)({
    width: '75%',
    "@media (max-width: 960px)": {
        width: '80%',
        margin: "0px auto"
    }
});

const ProfileContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1.5px solid #ffffff",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    marginRight: "20px",

});

const CameraIcon = styled("img")({
    width: "15px",
    height: "15px",
    marginBottom: "11px",
    zIndex: 2,
    position: "absolute"
});

const FaceIcon = styled("img")({
    width: "58px",
    height: "58px",
    position: "relative",
    zIndex: 1,
    borderRadius: '50%'
});

const TypographyName = styled(Typography)({
    fontSize: "24px"
});
const TypographyEdit = styled(Typography)({
    fontSize: '8px',
    color: '#ffffff',
    zIndex: 2,
    position: "absolute",
    marginTop: '20px',
});

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#87C122',
    padding: '33px',
    borderRadius: '10px',
    margin: '20px 35px',
});
const Header2 = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#87C122",
    padding: "33px",
    borderRadius: "10px",
    margin: "20px 35px",
    "@media (max-width: 600px)": {
      flexDirection: "row",
      alignItems: "center",
    },
  });
  
  const WalletSection = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "@media (max-width: 600px)": {
      alignItems: "center",
    },
  });
  
  const WalletInfo = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    },
  });
  
  const ProfileImage = styled("img")({
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border:'2px solid white',
    "@media (max-width: 600px)": {
      alignSelf: "center",
      marginTop: "15px",
    },
  });
  
  const DollarImage = styled("img")({
    width: "20px",
    height: "20px",
    borderRadius:'50%'
  });
  
const IconButtonForToogle = styled(IconButton)({
    marginTop: '6px',
    marginLeft: '10px',
    display: "none",
    "@media (max-width: 960px)": {
        display: "flex",
    },
});

const Sidebar = styled(Box)({
    width: '20%',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
    display: 'flex',
    justifyItems: 'center',
    margin: '0px 40px',
    '@media (max-width: 960px)': {
        margin: '20px auto',
        width: '80%',
        marginLeft: '30px'
    },
});

const StyledIcon = styled("div")({
    color: "#0F172A",
    margin: '0 15 0 13'
});

const ListItemLeft = styled(ListItem)({
    padding: '18px 33px',
    alignItems: 'center',
    cursor: 'pointer',
});

const ListItemIconSideBar = styled(ListItemIcon)({
    minWidth: '37px',
});

// Customizable Area End
