// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    children?: React.ReactNode;
    redirectedit?: void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isSidebarOpen: boolean;
    selectedSection: string;
    idUserRole: string;
    uploadImageState: boolean;
    redirect: boolean
    imageUrl: string
    imagefile: any
    fullname: string
    token:string;
    showDetails:boolean;
    paymentDetails:boolean;
    istoastopen2:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SideMenuController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
      userProfileGetApiCallId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSidebarOpen: true,
            selectedSection: 'Edit Profile',
            idUserRole: "",
            uploadImageState: false,
            redirect: false,
            imageUrl: "",
            imagefile: "",
            fullname: "",
            token:'',
            showDetails:false,
            paymentDetails:false,
            istoastopen2:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
                apiRequestCallId === this.userProfileGetApiCallId
            ) {
                if (responseJson && responseJson.data) {
                    if (this.state.idUserRole == "rider") {
                        let { first_name, last_name } = responseJson?.data
                        this.updateResponse(responseJson.data?.profile_upload,`${first_name} ${last_name}`)
                      } else {
                        let { first_name, last_name, profile_upload } = responseJson?.data?.attributes?.account?.attributes
                        this.updateResponse(profile_upload?.url, `${first_name} ${last_name}`)
                      }
                    // this.updateResponse(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const redirect = localStorage.getItem("redirectfrom")
        if (redirect == "Help Center") {
            this.setState({ selectedSection: "Help Center" })
        } else if (redirect == "settings") {
            this.setState({ selectedSection: "Settings" })
        } else if (redirect == "Trip History") {
            this.setState({ selectedSection: "Trip History" })
        }else if (redirect == "TripHistoryRider") {
            this.setState({ selectedSection: "appointment" })
        }
        const usertoken = localStorage.getItem("token")
        if(usertoken){ 
            this.setState({token:usertoken})
            this.getUserprofiledetails(usertoken)
        }
        this.handleRoleMenu();
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (this.state.redirect && prevState.redirect !== this.state.redirect) {
            this.setState({ selectedSection: 'Edit Profile', redirect: false })
        }
        if (prevState.selectedSection !== this.state.selectedSection) {
            this.getUserprofiledetails(this.state.token);
        }
    }

    handleSectionChange = (section: string) => {
        const isMobile = window.innerWidth <= 960;
        this.setState({
            selectedSection: section,
            isSidebarOpen: isMobile ? false : this.state.isSidebarOpen,
            showDetails:false,
            paymentDetails:false
        });
    };

    handleRoleMenu = async () => {
        let idUserRole = await getStorageData("role_id");
       
        this.setState({ idUserRole })
    };

    handleToggleSidebar = () => {
        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    };

    handleUploadImage = () => {
        this.setState({ uploadImageState: !this.state.uploadImageState })
    }
    handleBackToMenu = () => {
        this.setState({ selectedSection: `${configJSON.editProfile}` });
    }
    setSelectedSection = (section: string) => {
        this.setState({ selectedSection: section });
    };

    personalinfo = () => {
        this.setState({ redirect: true })
    }

    handleClosetoast = () => {
        this.setState({ istoastopen2: false })
      }
    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files ? fileInput.files[0] : null;
        
        // Allowed image types
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        
        if (file) {
            if (!allowedImageTypes.includes(file.type)) {
                // Show toast message for invalid image type
                this.setState({istoastopen2:true,imagefile: file})
                fileInput.value = ''; // Reset file input
                this.convertFileToUrl(file);
                return;
            }
    
            this.convertFileToUrl(file); // Your existing method to handle file conversion
            this.setState({ imagefile: file,istoastopen2:false }, () => {
                fileInput.value = ''; // Reset file input after setting state
            });
        }
    };
    convertFileToUrl = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ imageUrl: reader.result as string, uploadImageState: false })

        };
        reader.readAsDataURL(file);
    };

    removeUrl = () =>{
        this.setState({imagefile:'',imageUrl:'',uploadImageState:false})
    }

    updateResponse = (responseJson: any, fullname: string) => {
        if(responseJson){
            const profileuploadurl: string[] = responseJson
            this.setState({ imageUrl: responseJson})
            this.convertUrlsToFiles(profileuploadurl)
        }
        this.setState({fullname})
       
    }
    convertUrlsToFiles = async (url:any) => {
            const filename = url?.split("/").pop() || "file";
            const mimeType = this.getMimeType(filename); 
            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], filename, { type: mimeType });
            this.setState({ imagefile: [file][0] }); 
    };

    getMimeType(filename: string): string {
        const extension = filename.split(".").pop()?.toLowerCase();
        switch (extension) {
            case "jpeg":
            case "jpg":
                return "image/jpeg";
            case "pdf":
                return "application/pdf";
            default:
                return "application/octet-stream";
        }
    }

    getUserprofiledetails = (token: string) => {
        const header = {
            "Content-Type": configJSON.jsonApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userProfileEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
