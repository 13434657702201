
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from 'yup';
import React from "react";

const validateFiles = (filenames: string | string[], files: File | File[], validExtensions: string[], validFileTypes: string[], fieldName: string) => {
    // Ensure filenames and files are arrays
    const filenamesArray = Array.isArray(filenames) ? filenames : [filenames];
    const filesArray = Array.isArray(files) ? files : [files];

    // Validate file extensions
    const filenamesValid = filenamesArray.every((name) => {
        const extension = name.split('.').pop()?.toLowerCase();
        return extension && validExtensions.includes(extension);
    });

    // Validate file objects (mime type and extension)
    const filesValid = filesArray.every((file) => {
        if (file instanceof File) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const isValidMimeType = validFileTypes.includes(file.type);
            const isValidExtension = fileExtension && validExtensions.includes(fileExtension);
            return isValidMimeType || isValidExtension;
        }
        return false;
    });

    return filenamesValid && filesValid;
};

const fileLimit = (fileObjects: File | File[]) => {
    const fileArray = Array.isArray(fileObjects) ? fileObjects : [fileObjects];
    return fileArray.length <= 10;
};

const validationSchema = Yup.object().shape({
    licenceNumber: Yup.string()
        .required('Driving license number is required')
        .min(4, 'Driving license number must be at least 4 digits')
        .max(15, 'Driving license number cannot be more than 15 digits')
        .matches(/^\d+$/, 'Driving license number must contain only digits'),
    insaurenceNumber: Yup.string()
        .required('Insurence number is required')
        .min(4, 'Insurence number must be at least 4 digits')
        .max(15, 'Insurence number cannot be more than 15 digits')
        .matches(/^\d+$/, 'Insurence number must contain only digits'),
    insaurenceDocument: Yup.mixed()
        .required("Insurance document is required")
        .test(
            "fileType",
            "Only PDF, JPEG, JPG, PNG images are allowed",
            function () {
                const { parent } = this;
                const insaurencefilenames = parent.insaurenceDocument;
                const insaurencefileObjects = parent.insaurenceDocumentFile;

                if (!insaurencefilenames && !insaurencefileObjects) {
                    return false;
                }

                const validExtensions = ["pdf", "jpeg", "jpg", "png"];
                const validFileTypes = ["application/pdf", "image/jpg", "image/png", "image/jpeg"];

                return validateFiles(insaurencefilenames, insaurencefileObjects, validExtensions, validFileTypes, "insurance");
            }
        )
        .test(
            "fileLimit",
            "You can only upload up to 10 documents",
            function () {
                const { parent } = this;
                const insufileObjects = parent.insaurenceDocumentFile;
                return fileLimit(insufileObjects);
            }
        ),


    dmvdocument: Yup.string()
        .required('DMV document is required')
        .test(
            "fileType",
            "Only PDF, JPEG, JPG, PNG images are allowed",
            function () {
                const { parent } = this;
                const dmvdocumentfilenames = parent.dmvdocument;
                const dmvdocumentfileObjects = parent.dmvdocumentFile;

                if (!dmvdocumentfilenames && !dmvdocumentfileObjects) {
                    return false;
                }

                const validExtensions = ["pdf", "jpeg", "jpg", "png"];
                const validFileTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];

                return validateFiles(dmvdocumentfilenames, dmvdocumentfileObjects, validExtensions, validFileTypes, "dmv");
            }
        )
        .test(
            "fileLimit",
            "You can only upload up to 10 documents",
            function () {
                const { parent } = this;
                const dmvdocumentfileObjects = parent.dmvdocumentFile;
                return fileLimit(dmvdocumentfileObjects);
            }
        ),
    licencePicture: Yup.mixed()
        .required('License picture is required')
        .test(
            "fileType",
            "Only PDF, JPEG, JPG, PNG images are allowed",
            function () {
                const { parent } = this;
                const licencePicturefilenames = parent.licencePicture;
                const licencePicturefileObjects = parent.licencePictureFile;

                if (!licencePicturefilenames && !licencePicturefileObjects) {
                    return false;
                }

                const validExtensions = ["pdf", "jpeg", "jpg", "png"];
                const validFileTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];

                return validateFiles(licencePicturefilenames, licencePicturefileObjects, validExtensions, validFileTypes, "licence");
            }
        )
        .test(
            "fileLimit",
            "You can only upload up to 10 documents",
            function () {
                const { parent } = this;
                const licencePicturefileObjects = parent.licencePictureFile;
                return fileLimit(licencePicturefileObjects);
            }
        ),
    requirement: Yup.string().required('Requirement is required'),
});

const validationSchemavehicel = Yup.object().shape({
    vtype: Yup.string()
        .required('Vehicle Type is required'),
    VehicleMake: Yup.string()
        .required('Vehicle make is required')
        .max(20, 'Vehicle make cannot be more than 20 characters')
        .matches(/^[^\s]/, 'Vehicle make cannot start with a space'),
    vehiclemodel: Yup.string()
        .required('Vehicle Model is required')
        .max(25, 'Vehicle Model cannot be more than 25 characters')
        .matches(/^[^\s]/, 'Vehicle Model cannot start with a space'),
    vehicleyear: Yup.string()
        .required('Vehicle Year is required')
        .matches(/^\d{4}$/, 'Vehicle Year must be a 4-digit number')
        .max(4, 'Vehicle Year must be 4 digits')
        .test(
            'is-not-future-year',
            'Vehicle Year cannot be in the future',
            (value) => {
                const currentYear = new Date().getFullYear();
                return value && parseInt(value, 10) <= currentYear;
            }
        ),
    numberofSeats: Yup.string()
        .required('Number of Seats is required')
        .matches(/^\d+$/, 'Number of Seats must be a numeric value')
        .max(25, 'Number of Seats cannot be more than 25 digits'),

    VehicleLicenceplate: Yup.string()
        .required('Vehicle License Plate is required')
        .matches(/^[A-Za-z0-9 -]+$/, 'Vehicle License Plate can only contain letters and numbers')
        .max(15, 'Vehicle License Plate cannot be more than 15 characters'),
});
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    editdata: {}
    uploadImageFunction: () => void;
    imagefile?: any
    uploadImageState: boolean;
    handleFileChange: (data: any) => void;
    removePhoto: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    option: any
    ModalOpen: boolean
    drivereditstep: number
    vehicletypemodal: any
    vehicletype: any
    vehicletypeselected: any
    successMessage: string
    success: boolean,
    errorMessage: string
    tokenstring: string
    documentData: {
        licenceNumber: string
        insaurenceNumber: string
        insaurenceDocument: string
        licencePicture: string
        licencePictureFile: any
        dmvdocument: string
        dmvdocumentFile: any
        insaurenceDocumentFile: any
        requirement: string
        licenceNumberError: boolean
        insaurenceNumberError: boolean
        insaurenceDocumentError: boolean,
        licencePictureError: boolean,
        dmvdocumentError: boolean,
        requirementError: boolean,
    }
    carDetailsdata: {
        vtype: string
        VehicleMake: string
        vehiclemodel: string
        vehicleyear: string
        numberofSeats: string
        VehicleLicenceplate: string
        vtypeError: boolean
        VehicleMakeError: boolean
        vehiclemodelError: boolean
        vehicleyearError: boolean
        numberofSeatsError: boolean
        VehicleLicenceplateError: boolean
    }
    errors: any
    isToastOpen: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserProfileDriverController extends BlockComponent<Props, S, SS> {
    updateUserProfileDetailsApiCallId: any;
    userProfileGetApiCallId: any
    fileInputRef = React.createRef<HTMLInputElement>();
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            option: ["None", "Wheelchair", "Gurney"],
            ModalOpen: false,
            drivereditstep: 2,
            vehicletypemodal: false,
            vehicletype: ["Sedan", "Station wagon", "Hatchback", "SUV", "Minivan", "Other"],
            vehicletypeselected: "",
            successMessage: "Profile Updated Succesfully",
            errorMessage: "",
            success: false,
            tokenstring: "",
            documentData: {
                licenceNumber: "",
                insaurenceNumber: "",
                insaurenceDocument: "insaurence_docoment.pdf",
                licencePicture: "Licence_document.pdf",
                dmvdocument: "dmv_document.pdf",
                requirement: "Wheelchair",
                dmvdocumentFile: [],
                insaurenceDocumentFile: [],
                licencePictureFile: [],
                licenceNumberError: false,
                insaurenceNumberError: false,
                insaurenceDocumentError: false,
                licencePictureError: false,
                dmvdocumentError: false,
                requirementError: false,
            },
            carDetailsdata: {
                vtype: "",
                VehicleMake: "",
                vehiclemodel: "",
                vehicleyear: "",
                numberofSeats: "",
                VehicleLicenceplate: "",
                vtypeError: false,
                VehicleMakeError: false,
                vehiclemodelError: false,
                vehicleyearError: false,
                numberofSeatsError: false,
                VehicleLicenceplateError: false,
            },
            errors: {},
            isToastOpen: false,

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const token = localStorage.getItem("token");
        if (token) {
            this.setState({ tokenstring: token })
            this.getUserprofiledetails(token)
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
                apiRequestCallId === this.userProfileGetApiCallId
            ) {
                if (responseJson && responseJson.data) {

                    this.updateResponse(responseJson)
                }
            }
            if (apiRequestCallId === this.updateUserProfileDetailsApiCallId) {
                if (responseJson && responseJson.data) {
                    this.setState({ isToastOpen: true, success: true })
                    this.getUserprofiledetails(this.state.tokenstring)
                } else {
                    const firstErrorValue = responseJson.errors[0]
                    this.setState({ errorMessage: firstErrorValue, isToastOpen: true, success: false })
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    handleDialogClose2 = () => {
        this.setState({ ModalOpen: false })
    }
    handlevehicleModalclose = () => {
        this.setState({ vehicletypemodal: false })
    }

    handleClose = () => {
        this.setState({ isToastOpen: false })
    }


    handlechange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files, value } = event.target;
        if (files && files.length > 0) {
            this.fileHandling(files, name)
        } else {
            this.setState(prevState => ({
                documentData: {
                    ...prevState.documentData,
                    [name]: value,
                },
            }), async () => {
                try {
                    await validationSchema.validateAt(name, {
                        ...this.state.documentData,
                        [name]: value,
                    });

                    this.setState(prevState => ({
                        documentData: {
                            ...prevState.documentData,
                            [`${name}Error`]: false,
                        }
                    }));
                } catch (error) {
                    if (error instanceof Yup.ValidationError) {
                        this.setState(prevState => ({
                            documentData: {
                                ...prevState.documentData,
                                [`${name}Error`]: (error as Yup.ValidationError).message,
                            }
                        }));
                    }
                }
            });
        }
    };

    handleNextbackbutton = () => {
        if (this.state.drivereditstep == 2) {
            this.setState({ drivereditstep: 3 })
        } else {
            this.setState({ drivereditstep: 2 })
        }
    }
    getMimeType(filename: string): string {
        const extension = filename.split(".").pop()?.toLowerCase();
        switch (extension) {
            case "jpeg":
            case "jpg":
                return "image/jpeg";
            case "pdf":
                return "application/pdf";
            default:
                return "application/octet-stream";
        }
    }

    convertUrlsToFiles = async (urls: string[], key: string) => {
        const files: File[] = [];

        for (const url of urls) {
            const filename = url.split("/").pop() || "file";
            const mimeType = this.getMimeType(filename);
            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], filename, { type: mimeType });
            files.push(file);
        }
        if (key == "dvm") {
            this.setState((prevState: { documentData: any; }) => ({
                documentData: {
                    ...prevState.documentData,
                    dmvdocumentFile: files
                }
            }));
        }
        else if (key == "licencepicture") {
            this.setState((prevState: { documentData: any; }) => ({
                documentData: {
                    ...prevState.documentData,
                    licencePictureFile: files
                }
            }));
        }
        else if (key == "insaurence") {
            this.setState((prevState: { documentData: any; }) => ({
                documentData: {
                    ...prevState.documentData,
                    insaurenceDocumentFile: files
                }
            }));
        }

    }

    updateResponse = (responseJson: any) => {
        const dmvdocumenturls: string[] = responseJson.data.attributes?.dmv_document_uploads?.map((item: { url: string }) => item.url) || [];
        const licencePicture: string[] = responseJson.data.attributes?.driving_licence_picture_uploads.map((item: { url: string }) => item.url) || [];
        const insaurenceDocument: string[] = responseJson.data.attributes?.auto_insurance_policy_document_uploads.map((item: { url: string }) => item.url) || [];
        const dvmdocumentname: string[] = responseJson.data.attributes?.dmv_document_uploads?.map((item: { file_name: string }) => item.file_name) || [];
        const licencePicturename: string[] = responseJson.data.attributes?.driving_licence_picture_uploads.map((item: { file_name: string }) => item.file_name) || [];
        const insaurenceDocumentname: string[] = responseJson.data.attributes?.auto_insurance_policy_document_uploads.map((item: { file_name: string }) => item.file_name) || [];
        this.convertUrlsToFiles(dmvdocumenturls, "dvm")
        this.convertUrlsToFiles(licencePicture, "licencepicture")
        this.convertUrlsToFiles(insaurenceDocument, "insaurence")
        this.handleResponseurl(dmvdocumenturls, licencePicture, insaurenceDocument, responseJson, dvmdocumentname, licencePicturename, insaurenceDocumentname)
    }

    handleAddPhotoClick = () => {
        if (this.fileInputRef.current) {
            this.fileInputRef.current.click();
        }
    };
    getAdditionalRequirements(responseJson:any) {
        const additionalRequirements = responseJson.data.attributes?.additional_requirements;
        if (additionalRequirements === 'no_requirements') {
          return "None";
        }
        return additionalRequirements || "none";
      }
    handleResponseurl = (dmvdocumenturls: string[], licencePicture: string[], insaurenceDocument: string[], responseJson: any, dvmdocumentname: any, licencePicturename: any, insaurenceDocumentname: any) => {
        this.setState(prevState => ({
            documentData: {
                ...prevState.documentData,
                licenceNumber: responseJson.data.attributes?.driving_licence_number || "",
                insaurenceNumber: responseJson.data.attributes?.auto_insurance_policy_number || "",
                requirement: this.getAdditionalRequirements(responseJson),
                dmvdocument: dvmdocumentname,
                insaurenceDocument: insaurenceDocumentname,
                licencePicture: licencePicturename,
                licencePictureFile: licencePicture,
                insaurenceDocumentFile: insaurenceDocument,
                dmvdocumentFile: dmvdocumenturls
            },
            carDetailsdata: {
                ...prevState.carDetailsdata,
                vtype: this.reverseMapVehicleType(responseJson.data.attributes?.vehicle_type),
                vehiclemodel: responseJson.data.attributes?.vehicle_model,
                numberofSeats: responseJson.data.attributes?.number_of_seats,
                vehicleyear: responseJson.data.attributes?.vehicle_year,
                VehicleMake: responseJson.data.attributes?.vehicle_make,
                VehicleLicenceplate: responseJson.data.attributes?.vehicle_licence_plate
            }
        }));
    }



    fileHandling = (files: FileList, name: string) => {
        const fileArray = Array.from(files);
        const fileNames = fileArray.map((file) => file.name.trim());

        this.setState(
            (prevState) => ({
                documentData: {
                    ...prevState.documentData,
                    [name]: fileNames,
                    [`${name}File`]: fileArray,
                    [`${name}Error`]: []
                }
            }),
            async () => {
                const errors: string[] = [];
                try {
                    await validationSchema.validateAt(name, {
                        ...this.state.documentData,
                        [name]: fileNames,
                    });
                } catch (error) {
                    if (error instanceof Yup.ValidationError) {
                        errors.push(error.message);
                    }
                }

                this.setState((prevState) => ({
                    documentData: {
                        ...prevState.documentData,
                        [`${name}Error`]: errors.length ? errors[0] : false,
                    }
                }));
            }
        );
    };
    handlechangecard = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            carDetailsdata: {
                ...prevState.carDetailsdata,
                [name]: value,
            }
        }), async () => {
            try {
                await validationSchemavehicel.validateAt(name, {
                    ...this.state.carDetailsdata,
                    [name]: value,
                });

                this.setState(prevState => ({
                    carDetailsdata: {
                        ...prevState.carDetailsdata,
                        [`${name}Error`]: false,
                    }
                }));
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    this.setState(prevState => ({
                        carDetailsdata: {
                            ...prevState.carDetailsdata,
                            [`${name}Error`]: (error as Yup.ValidationError).message,
                        }
                    }));
                }
            }
        });
    };
    mapVehicleType = (vehicleType:any) => {
        const mapping:any = {
            'Sedan': 'sedan',
            'Station wagon': 'station_wagon',
            'Hatchback': 'hatchback',
            'SUV': 'suv',
            'Minivan': 'minivan',
            'Other': 'other'
        };
        return mapping[vehicleType] || vehicleType; // Returns the mapped value or the original if not found
    };
    reverseMapVehicleType = (vehicleType:any) => {
        const reverseMapping:any = {
            'sedan': 'Sedan',
            'station_wagon': 'Station wagon',
            'hatchback': 'Hatchback',
            'suv': 'SUV',
            'minivan': 'Minivan',
            'other': 'Other'
        };
        return reverseMapping[vehicleType] || vehicleType; 
    };
    handleSubmitdata = async () => {
        const vehicleformdata = new FormData();
const vehType=this.mapVehicleType(this.state.carDetailsdata.vtype)
        vehicleformdata.append('account[driver_detail_attributes][vehicle_type]', vehType)
        vehicleformdata.append('account[driver_detail_attributes][vehicle_model]', this.state.carDetailsdata.vehiclemodel)
        vehicleformdata.append('account[driver_detail_attributes][vehicle_year]', this.state.carDetailsdata.vehicleyear)
        vehicleformdata.append('account[driver_detail_attributes][number_of_seats]', this.state.carDetailsdata.numberofSeats)
        vehicleformdata.append('account[driver_detail_attributes][vehicle_licence_plate]]', this.state.carDetailsdata.VehicleLicenceplate)
        vehicleformdata.append('account[driver_detail_attributes][vehicle_make]', this.state.carDetailsdata.VehicleMake)
       
        const documentformdata = new FormData();
        documentformdata.append('account[driver_detail_attributes][driving_licence_number]', this.state.documentData.licenceNumber)
        documentformdata.append('account[driver_detail_attributes][auto_insurance_policy_number]', this.state.documentData.insaurenceNumber)
        this.state.documentData.insaurenceDocumentFile?.forEach((file: any, index: any) => {
            documentformdata.append('account[driver_detail_attributes][auto_insurance_policy_document_uploads][]', file);
        });
        this.state.documentData.dmvdocumentFile?.forEach((file: any, index: any) => {
            documentformdata.append('account[driver_detail_attributes][dmv_document_uploads][]', file);
        });
        this.state.documentData.licencePictureFile?.forEach((file: any, index: any) => {
            documentformdata.append('account[driver_detail_attributes][driving_licence_picture_uploads][]', file);
        });
        documentformdata.append('account[driver_detail_attributes][additional_requirements]', this.state.documentData.requirement)
       if(this.props.imagefile){
        documentformdata.append('account[profile_upload]', this.props.imagefile)
        vehicleformdata.append('account[profile_upload]', this.props.imagefile)
       }
        if (this.state.drivereditstep == 3) {
            this.updateVehicledata(vehicleformdata)
        } else {
            this.updateDocumentsdetails(documentformdata)
        }
    };

    updateVehicledata = async (vehicleformdata: any) => {
        try {
            await validationSchemavehicel.validate(this.state.carDetailsdata, { abortEarly: false });
            this.updateUserProfileDetails(vehicleformdata)
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const newErrors: { [key: string]: string } = {};
                error.inner.forEach((err) => {
                    if (!newErrors[err.path]) {
                        newErrors[err.path] = err.message;
                    }
                });
                this.setState(prevState => ({
                    carDetailsdata: {
                        ...prevState.carDetailsdata,
                        ...Object.fromEntries(
                            Object.entries(newErrors).map(([key, value]) => [`${key}Error`, value])
                        )
                    }
                }));
            }
        }
    }

    updateDocumentsdetails = async (documentformdata: any) => {
        try {
            await validationSchema.validate(this.state.documentData, { abortEarly: false });
            this.updateUserProfileDetails(documentformdata);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const newErrors: { [key: string]: string } = {};
                error.inner.forEach((err) => {
                    if (!newErrors[err.path]) {
                        newErrors[err.path] = err.message;
                    }
                });
                this.setState((prevState) => ({
                    documentData: {
                        ...prevState.documentData,
                        ...Object.fromEntries(
                            Object.entries(newErrors).map(([key, value]) => [`${key}Error`, value])
                        ),
                    },
                }));
            }
        }
    };



    updateUserProfileDetails = (formData: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const header = {
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.updateUserProfileDetailsApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.Updateprofile}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiUpdateUserType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    getUserprofiledetails = (token: string) => {
        const header = {
            "Content-Type": configJSON.contactUsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userProfileEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
