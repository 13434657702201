
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React, { createRef } from "react";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    backTofirstpage: () => {}
    rideid:number 
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    map: any;
    showChat: boolean;
    availableHeight: string;
    ordersummarydetails: {
        start: string
        destination: string
        car_type: string
        date: string
        time: string
        status:string
        available_driver_ids:any
    };
    driverDetails:any;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class ChooseFromMapController extends BlockComponent<Props, S, SS> {
    headerRef: any;
    orderSummaryRef: any;
    orderSummaryApiCallId = "";
    userprofiledetailsApiCallId:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),


        ];
        this.state = {
            map: [],
            showChat: true,
            availableHeight: "100vh",
            ordersummarydetails: {
                start: "",
                destination: "",
                car_type: "",
                date: "",
                time: "",
                status:'',
                available_driver_ids:[],
               
            },
            driverDetails:{}
        };
        this.headerRef = createRef();
        this.orderSummaryRef = createRef();
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.calculateAvailableHeight();
        window.addEventListener("resize", this.calculateAvailableHeight);
        const token = localStorage.getItem("token")
        const id = localStorage.getItem("rideid")
        if (token) {
            this.orderSummaryApi(token,this.props.rideid | Number(id))
        }
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
                apiRequestCallId === this.orderSummaryApiCallId
            ) {
                if (responseJson && responseJson.data) {
                    this.setState({ ordersummarydetails: responseJson.data.attributes })
                    this.checkRideAccpted(responseJson.data.attributes)
                }

            }
            if (
                apiRequestCallId === this.userprofiledetailsApiCallId
            ) {
                console.log("dataaa",responseJson)
                if (responseJson && responseJson.data) {
                   this.setState({driverDetails:responseJson.data.attributes})                   
                }

            }

        }

        // Customizable Area End
    }

    // Customizable Area Start
    getUrl = (driverDetails:any) => {
        const profileUrl = driverDetails.account?.attributes?.profile_upload?.url;
        return profileUrl ? profileUrl : 'https://media.istockphoto.com/id/1451587807/vector/user-profile-icon-vector-avatar-or-person-icon-profile-picture-portrait-symbol-vector.jpg?s=612x612&w=0&k=20&c=yDJ4ITX1cHMh25Lt1vI1zBn2cAKKAlByHBvPJ8gEiIg=';
    };
    getDriverName = (driverDetails:any) => {
        const { first_name, last_name } = driverDetails?.account?.attributes || {};

        const capitalize = (str: any) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';

        return `${capitalize(first_name)} ${capitalize(last_name)}`.trim();
    };
    checkRideAccpted = (ordersummarydetails: { status: string; available_driver_ids: any[]; }) => {
        if (ordersummarydetails.status === "accepted") {
            this.fetchUserProfile(ordersummarydetails.available_driver_ids?.[0])
        }
    }
    fetchUserProfile = (id: any) => {
        const header = {
            "Content-Type": configJSON.contactUsApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userprofiledetailsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getuserprofilendpoint2}?id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    calculateAvailableHeight = () => {
        const headerHeight = this.headerRef.current?.offsetHeight || 0;
        const orderSummaryHeight = this.orderSummaryRef.current?.offsetHeight || 0;
        const availableHeight = `calc(100vh - ${headerHeight + orderSummaryHeight}px)`;
        this.setState({ availableHeight });
    };
    navigateToChat = () => {
        const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ChatRiderDriver");
        navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigate);
    }
    closeChat = () => {
        this.setState({ showChat: false })
    }
    navigateToProjectTemplate = () => {
        localStorage.setItem("redirectfrom","Trip History")
        const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SideMenuBasic");
        navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigate);
    }

    formatDate = (dateString: string): string => {
        const date = new Date(dateString);

        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
        };
        const formattedDate = date.toLocaleDateString('en-US', options).replace(/,/g, '');
        // Rearrange the components manually
        const [day, month, dayNum] = formattedDate.split(' ');
        return `${day} ${dayNum} ${month}`;

    };

    formatTime = (timeString: string, addMinutes: number): string => {
        try {
            const [, timePart] = timeString.split('T');
            let [hours, minutes] = timePart.split(':');
            let numericHours = parseInt(hours, 10);
            let numericMinutes = parseInt(minutes, 10);
            numericMinutes += addMinutes;
            if (numericMinutes >= 60) {
                numericHours += Math.floor(numericMinutes / 60);
                numericMinutes %= 60;
            }
            numericHours %= 24;
            const period = numericHours >= 12 ? 'Pm' : 'Am';
            const adjustedHours = numericHours % 12 || 12;
            const adjustedMinutes = numericMinutes.toString().padStart(2, '0');
            return `${adjustedHours}:${adjustedMinutes} ${period}`;
        } catch (error) {
            return 'Invalid time';
        }
    };

    

    orderSummaryApi = (token: string,rideid:number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderSummaryApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ordersummaryapi}${rideid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}