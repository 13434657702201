
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import dayjs, { Dayjs } from 'dayjs';
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    planyourridedata: {
        selectedCar: {
            name: string
        },
        startlocation: string,
        destinationlocation: string,
        fromTripHistoryScreen:boolean;
        rideId:any;
    }
    handleSuccessclose: (...args: any[]) => void, 
    handleSucessopen: (...args: any[]) => void
    getid: (...args: any[]) => void, 
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    time: any;
    selectedDate: any
    currentTime: any
    isToastOpen: boolean
    errormessage: string
    error: boolean
    successMessage: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ChooseDatesTimeController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    scheduleRideApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),

        ];
        this.state = {
            time: "14:30",
            selectedDate: new Date(),
            currentTime: dayjs(new Date()),
            isToastOpen: false,
            errormessage: "",
            error: false,
            successMessage: "string"
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
                apiRequestCallId === this.scheduleRideApiCallId
            ) {
                if (responseJson && responseJson.data) {
                    this.setState({ error: false, successMessage: "Your ride is scheduled", isToastOpen: true })
                    this.props.getid(responseJson.data.id)
                    setTimeout(() => {
                        this.props.handleSucessopen()
                    }, 1000)
                }
                else {
                    console.log(responseJson)
                    const error = responseJson.errors[0]
                    this.setState({ errormessage: error, isToastOpen: true, error: true })
                }
            }

        }

        // Customizable Area End
    }

    // Customizable Area Start
    handleTimeChange = (newValue: any) => {
        this.setState({ currentTime: newValue })
    };

    scheduleRide = () => {
        const { startlocation, destinationlocation, selectedCar } = this.props.planyourridedata;
        if (!startlocation || !destinationlocation || !selectedCar?.name) {
            this.setState({
                isToastOpen: true,
                errormessage: "Please plan your ride first",
                error: true
            });
        } else {
            const selectedDate = dayjs(this.state.selectedDate);
            const selectedTime = dayjs(this.state.currentTime);
            const selectedDateTime = selectedDate
                .set('hour', selectedTime.hour())
                .set('minute', selectedTime.minute())
                .set('second', selectedTime.second());
            const now = dayjs();
            if (selectedDateTime.isAfter(now)) {
                console.log('The selected date and time are in the future.');
                const token = localStorage.getItem("token")
                if (token) {
                    this.schedulerideApi(token)
                }
            } else {
                this.setState({ isToastOpen: true, errormessage: "The selected date and time should be in the future", error: true })
            }
        }

    }

    handleClose = () => {
        this.setState({ isToastOpen: false })
    }


    handleDateClick = (date: any) => {
        this.setState({ selectedDate: date });
    };

    schedulerideApi = (token: string) => {
        const { selectedCar, startlocation, destinationlocation,fromTripHistoryScreen,rideId } = this.props.planyourridedata
        console.log(this.state.selectedDate)
        const body = {
            location: {
                start: startlocation,
                destination: destinationlocation,
                date: dayjs(this.state.selectedDate).format('YYYY-M-D'),
                time: this.state.currentTime.format('HH:mm:ss'),
                car_type: selectedCar.name
            }
        }
        console.log(body, "body")


        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };


        if (fromTripHistoryScreen) {
            this.callUpdateAPI(body,header,rideId);
          } else {
            this.callCreateAPI(body,header);
          }
        
    };
    callCreateAPI=(body:any,header:any)=>{
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.scheduleRideApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.schedulerideapi
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    callUpdateAPI=(body:any,header:any,rideId:any)=>{
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.scheduleRideApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
             `${configJSON.updaterideapi}${rideId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.examplePatchAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
