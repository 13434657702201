import React from "react";

// Customizable Area Start
import { Grid, Paper,Typography,Table, TableBody, TableRow, TableCell, Box,IconButton,Button } from '@mui/material';
import { styled } from '@mui/system';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Calendar  ,momentLocalizer,DateLocalizer} from "react-big-calendar";
import MyTasksController, {
  Props
} from "./MyTasksController.web";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "../../email-account-registration/src/EmailAccountRegistratin.css"
import moment from 'moment'
import {plus} from './assets';
const localizer = momentLocalizer(moment);
import CustomToolbar from "../../../components/src/CustomToolBar.web";
// Customizable Area End

// Customizable Area Start
const Container = styled(Grid)({
  padding: '30px', 
});

const StyledPaper = styled(Paper)({
  padding: '12px', 
  borderRadius:'8px',
  marginBottom:'10px',
  height:'45px',
});
const StyledText = styled(Typography)({
 
  fontSize:'16px',
  fontWeight:700,
});
const StyledText2 = styled(Typography)({
 
  fontSize:'18px',
  fontWeight:700,
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0 18px", 
  width: "100%",
});


const RowContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "20px",
  borderLeft: "4px solid #87C122", 
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  width: "100%"
});
const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: "16px",
  whiteSpace: "nowrap", 
});
interface CustomEvent {
  start: Date;
  end: Date;
  title: string;
}
// Customizable Area End

export default class MyTasks extends MyTasksController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
     <div>
       <NavigationMenu navigation={this.props.navigation} id={""} index={1} />
        <Container container spacing={2}>
          <Grid item xs={12} sm={6} sx={{ backgroundColor: '#ECFDF5', padding: '0px' }}>
            <StyledPaper>
              <StyledText>Rides List</StyledText>
            </StyledPaper>
            <Box sx={{ overflowX: "auto", width: "100%" }}> 
              <StyledTable sx={{ width: "100%", minWidth: 600 }}> 
                <TableBody>
                  {this.state.rides.map((ride:any, index:any) => (
                    <TableRow key={index} sx={{ borderBottom: "none", padding: 0, margin: 0 }}>
                      <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
                        <RowContainer
                          sx={{
                            display: "flex",
                            padding: "8px",
                            width: "100%",
                            alignItems: "flex-start", // Align other content at the top
                          }}
                        >
                          <Box sx={{ flex: "1 1 100px" }}>
                            <Typography sx={webStyle.typographyStyle}>
                             {this.formatDate(ride.attributes.date)}, 
                            </Typography>
                            <Typography sx={webStyle.typographyStyle}>
                            {this.formatTime(ride.attributes.time)}
                            </Typography>
                          </Box>

                          <Box sx={{ flex: "1 1 150px" }}>
                            <Typography sx={webStyle.typographyStyle}>
                              From:
                            </Typography>
                            <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>{ride.attributes.start}</Typography>
                          </Box>

                          {/* To Address */}
                          <Box sx={{ flex: "1 1 150px" }}>
                            <Typography variant="body1" fontWeight="bold" sx={webStyle.typographyStyle}>
                              To:
                            </Typography>
                            <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>{ride.attributes.destination}</Typography>
                          </Box>

                          {/* Price */}
                          <Box sx={{ flex: "1 1 80px" }}>
                            <Typography variant="body1" fontWeight="bold" sx={webStyle.typographyStyle}>
                              Price:
                            </Typography>
                            <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>${ride.attributes.price}</Typography>
                          </Box>

                          {/* Three Dots Icon and Status */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: ride.status === "in progress" || ride.status === "scheduled" ? "space-between" : "center",
                              height: "100%", // Make full height to align top and bottom
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <MoreVertIcon />
                            </Box>
                            <Typography
                              variant="caption"
                              sx={{
                                padding: "2px 2px",
                                marginTop: ride.status === "in progress" || ride.status === "scheduled" ? "auto" : 1,
                                backgroundColor: this.getStatusStyles(ride.status).backgroundColor,
                                color: this.getStatusStyles(ride.status).color,
                                borderRadius: "8px",
                                fontSize: "0.75rem",
                              }}
                            >
                              Scheduled
                            </Typography>
                          </Box>
                        </RowContainer>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Box>
            <Box
       
       
      
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'white',
            color: '#0F172A',
            width: '50%',
            fontSize:'16px',
            fontWeight:700,
            borderRadius: '8px',
            marginBottom: '20px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          Export Your Rides
        </Button>

        <Typography
          variant="body1"
          sx={{
            color: '#0F172A',
            width: '50%',
            marginBottom: '20px',
          }}
        >
          Easily add your planned rides to your personal calendar and manage your schedule seamlessly.
        </Typography>

              <Box style={webStyle.BoxStyle} className="ButText">
                <IconButton sx={webStyle.IconButton}>
                  <img src={plus.default} alt="Plus Icon" style={{ verticalAlign: 'middle', width: '20px', height: '20px' }} />
                </IconButton>
                <Typography
                  variant="button"
                  sx={webStyle.typography}
                >
                  Export to Google Calendar
                </Typography>
              </Box>
              <Box style={webStyle.BoxStyle} className="ButText">
                <IconButton sx={webStyle.IconButton}>
                  <img src={plus.default} alt="Plus Icon" style={{ verticalAlign: 'middle', width: '20px', height: '20px' }} />
                </IconButton>
                <Typography
                  variant="button"
                  sx={webStyle.typography}
                >
                  Export to Apple Calender
                </Typography>
              </Box>
              <Box style={webStyle.BoxStyle} className="ButText">
                <IconButton sx={webStyle.IconButton}>
                  <img src={plus.default} alt="Plus Icon" style={{ verticalAlign: 'middle', width: '20px', height: '20px' }} />
                </IconButton>
                <Typography
                  variant="button"
                  sx={webStyle.typography}
                >
                 Export to Outlook
                </Typography>
              </Box>
              
      </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ backgroundColor: '#ECFDF5', padding: '0px' }}>
            <StyledPaper>
              <StyledText2>iCalendar</StyledText2>
            </StyledPaper>
            <div style={{ height: "100%" }}>
              <Calendar<CustomEvent>
                 className="custom-calendar"
                localizer={localizer}
                events={this.state.events}
                view={this.state.view}
                onView={(view) => this.setState({ view })}
                startAccessor="start"
                endAccessor="end"
                data-test-id="mock-calendar"
                style={{ height: "80vh", backgroundColor: "white", borderRadius: '8px' }}
                components={{
                  toolbar: CustomToolbar,
                  event: ({ event }) => <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    fontSize: '10px',
                    textAlign: 'center',
                    whiteSpace: 'normal',
                    padding:'1px',
                    backgroundColor:'#ECFDF5',
                    color:'#0F172A',
borderRadius:'5px',
borderLeft: '4px solid #34D399', // Apply left border
paddingLeft: '2px', // Add spacing to avoid overlap with border
                  }}
                  data-test-id="eventDiv">
                    {event.title}
                  </div>, 
                }}
                formats={{
                  timeGutterFormat: 'HH:mm',
                  eventTimeRangeFormat: () => '', // Remove time from event
                  monthHeaderFormat: (date: Date, culture?: string, localizer?: DateLocalizer) =>
                    localizer?.format(date, 'MMM DD') || '',
                }}
                onSelectEvent={(event) => {
                  this.navigateToEditRide()
                }}
              />
            </div>
          </Grid>
        </Container>
     </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
    typography:{ color: '#1D6FB8', textTransform: 'none', fontSize: '16px', fontWeight: 700 },
    IconButton:{ padding: '0 !important', marginRight: '8px', display: 'flex', alignItems: 'center',paddingBottom:'3px' },
    BoxStyle:{display:'flex',alignItems:'center',marginBottom:'10px'},
 typographyStyle:{ whiteSpace: "normal", wordBreak: "break-word",fontSize:'16px',fontWeight:700,color:'#0F172A'
 },
 secondaryText:{ whiteSpace: "normal", wordBreak: "break-word",fontSize:'16px',fontWeight:400,color:'#0F172A' },
  };
  // Customizable Area End
