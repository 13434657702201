// Customizable Area Start
import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  styled,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";
import { contactus, errorimage, successimage } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <Box>
      <NavigationMenu navigation={this.props.navigation} id={""} index={2}/>
        {/* Customizable Area Start */}
        <Box style={webstyle.contactuscontainer}>

          <Box style={webstyle.contactustextbox}>
            <Contactustext>Contact Us</Contactustext>
          </Box>
          <Contactusbox >
            <Box style={webstyle.contactusimagebox}>
              <Contactusimage>
                <img src={contactus.default} style={{ width: "100%" }} />
              </Contactusimage>
              <Typography style={webstyle.needhelptext}>Need Help? We're Here.</Typography>
              <Typography style={webstyle.reachouttext}>Reach out for any questions or concerns.</Typography>
            </Box>
            <Box>

            </Box>
            <ContactusInputGroup>
              <InputWrapper>
                <ContactusCustomLabel>Name</ContactusCustomLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px" }}
                  placeholder="Enter your name"
                  InputLabelProps={{ shrink: true }}
                  data-test-id="contact-us-name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  name="name"
                  value={this.state.name}
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '8px', 
                      background:"white", 
                    },
                    inputProps: {
                      style: {
                        color: "#0F172A", 
                        fontSize: '14px',
                        fontWeight: 400,
                      },
                    }
                  }}
                />
              </InputWrapper>
            </ContactusInputGroup>
            <ContactusInputGroup>
              <InputWrapper>
                <ContactusCustomLabel>Email</ContactusCustomLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px" }}
                  placeholder="Enter your email"
                  InputLabelProps={{ shrink: true }}
                  data-test-id="contact-us-email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  name="Email"
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '8px', 
                      background:"white", 
                    },
                    inputProps: {
                      style: {
                        color: "#0F172A", 
                        fontSize: '14px',
                        fontWeight: 400,
                      },
                    }
                  }}
                />
              </InputWrapper>
            </ContactusInputGroup>
            <ContactusInputGroup>
              <InputWrapper>
                <ContactusCustomLabel>Phone Number</ContactusCustomLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px" }}
                  data-test-id="contact-us-number"
                  InputLabelProps={{ shrink: true }}
                  name="Phone Number"
                  placeholder="Enter your number"
                  value={this.state.phoneNumber}
                  onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                  inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
                  onKeyDown={(e) => {
                    if (!/\d/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start" style={{ marginRight: '8px' }}>
                        +1
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                      background: "white",
                    },
                    inputProps: {
                      style: {
                        color: "#0F172A",
                        fontSize: '14px',
                        fontWeight: 400,
                      },
                    }
                  }}
                />
              </InputWrapper>
            </ContactusInputGroup>
            <ContactusInputGroup>
              <InputWrapper>
                <ContactusCustomLabel>Message</ContactusCustomLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px" }}
                  placeholder="Any details you would like to share?"
                  data-test-id="contact-us-message"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  name="Message"
                  value={this.state.message}
                  multiline
                  minRows={3}
                  InputProps={{
                    style: {
                      borderRadius: '8px', 
                      background:"white", 
                    },
                    inputProps: {
                      style: {
                        color: "#0F172A", 
                        fontSize: '14px',
                        fontWeight: 400,
                      },
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "#94A3B8", fontSize: "16px", fontWeight: 400
                    },
                  }}
                />
              </InputWrapper>
            </ContactusInputGroup>
            <Box style={webstyle.contactusbuttonboxcontainer}>
              <Box style={webstyle.contactusbuttonbox}>
                <Box style={webstyle.sendbuttonbox} onClick={this.handleSubmit} data-test-id="send-contact-us-query">
                  <Button style={webstyle.agreetextcolor} >Send message</Button>
                </Box>
                <Box style={webstyle.cancelbuttonbox} onClick={()=>this.redirectToDashboard("SideMenuBasic")} >
                  <Button style={webstyle.canceltextcolor} data-test-id="redirectdashboardid">Cancel</Button>
                </Box>
              </Box>

            </Box>

          </Contactusbox>

        </Box>
        <Modal
          open={this.state.openmodal
          }
          // onClose={}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

          }}
        >
          <Box
            sx={{
              width: 600,
              // height:617,
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: '21.51px',
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              justifyContent: 'center',
              alignItems: "center",
              gap: "20px",
              '@media (max-width: 650px)': {
                width: 420,
                p: 4,
              },
              '@media (max-width: 600px)': {
                width: 390,
                p: 4,
              },
              '@media (max-width: 465px)': {
                width: 360,
                p: 4,
              },
              '@media (max-width: 435px)': {
                width: 310,
                p: 4,
              },
              '@media (max-width: 418px)': {
                width: 300,
                p: 4,
              },
              '@media (max-width: 393px)': {
                width: 310,
                p: 4,
              },
              '@media (max-width: 375px)': {
                width: 280,
                p: 4,
              },
              '@media (max-width: 355px)': {
                width: 260,
                p: 3,
              },
            }}
          >
            <img src={this.state.error ? errorimage.default : successimage.default}  style={{width:this.state.error ? "" : "100%",height:this.state.error ? "" : "275px"}}/>
            {this.state.error ? <> <Typography style={webstyle.errortext}>Error</Typography>
              <Typography style={{
                fontSize: "21.51px",
                fontWeight: 700,
                lineHeight: "32.26px",
                color: "#0F172A",
                whiteSpace: "nowrap"
              }}>{this.state.errormessage}</Typography>
              <Errortextmessage >It looks like you forgot to enter a {this.state.errormessage.split(" ")[3]}.</Errortextmessage>
              <Errortextmessage > Please type your {this.state.errormessage.split(" ")[3]} before submitting.</Errortextmessage>
              <Box style={webstyle.tryagainbutton} onClick={() => this.setState({ openmodal: false })} data-test-id ="contactus-tryagain">
                <Button style={webstyle.agreetextcolor} >Try again</Button>
              </Box>
            </>
              : <> <Typography style={webstyle.gotittext}>Got it!</Typography>
                <Box>
                  <Successmessage >Thank you for your message! We’ve received it</Successmessage>
                  <Successmessage > and will get back to you shortly.</Successmessage>
                </Box>
                <Box style={webstyle.donebutton} onClick={() => this.setState({ openmodal: false })} data-test-id="contact-sucess">
                  <Button style={webstyle.donetextcolor} >Done</Button>
                </Box>
              </>}

          </Box>
        </Modal>
        {/* Customizable End Start */}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const Contactusbox = styled(Box)({
  flexDirection: "column" as "column",
  justifyContent: "center",
  gap: "20px",
  width: "655px",
  display: "flex",
  "@media (max-width: 680px)": {
    width: "90%",
  },
})
const InputWrapper = styled(Box)({
  position: "relative",
  marginBottom: "24px",
  width: "100%",
  zIndex: 2,
});


const ContactusCustomLabel = styled(Typography)({
  position: "absolute",
  top: "-30px",
  backgroundColor: "white",
  padding: "0 4px",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  zIndex: 1,
  marginBottom: "8px",
  color: "#334155",
  background:"transparent"
});

const Contactustext = styled(Box)({
  fontWeight: 400,
  lineHeight: "40px",
  color: "#1E293B",
  fontSize: "30px",
  "@media (max-width: 500px)": {
    fontSize: "25px",
  },
  "@media (max-width: 430px)": {
    fontSize: "20px",
  },
  "@media (max-width: 450px)": {
    fontSize: "18px",
  },
  "@media (max-width: 350px)": {
    fontSize: "16px",
  },
})
const Errortextmessage = styled(Box)({
  fontSize: "21.51px",
  fontWeight: 400,
  color: "#0F172A",
  textAlign:"center"
})
const Successmessage = styled(Box)({
  fontSize: "25px",
  fontWeight: 400,
  color: "#0F172A",
  textAlign:"center"
})



const Contactusimage = styled(Box)({
  width: "100%",
  "@media (max-width: 518px)": {
    width: "100%",
  },
})

const ContactusInputGroup = styled(Box)({
  display: "flex",
  gap: "20px",
  justifyContent: "space-between",
});

const webstyle = {
  contactuscontainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },

  errortext: {
    fontSize: "21.51px",
    fontWeight: 700,
    lineHeight: "32.26px",
    color: "#DC2626"
  },

  gotittext: {
    fontSize: "28.12px",
    fontWeight: 700,
    lineHeight: "40.62px",
    color: "#87C122"
  },

  contactusbuttonboxcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },

  contactusbuttonbox: {
    width: "70%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: '10px'
  },

  notext: {
   
  },

  contactusimagebox: {
    display: "flex",
    gap: "20px",
    marginTop: "10px",
    flexDirection: "column" as "column",
  },

  needhelptext: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#0F172A"
  },

  reachouttext: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475569"
  },

  contactustextbox: {
    width: "200px",
    height: "40px",
    textAlign: "center" as "center",
    borderRadius: "5px"
  },



  sendbuttonbox: {
    background: "#1D6FB8",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
    color: "black",
    width: "100%",
    display: "flex",
  },

  tryagainbutton: {
    background: "#DC2626",
    color: "#FFFFFF",
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    height: "56px"
  },
  donebutton: {
    background: "#87C122",
    color: "#FFFFFF",
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    height: "56px"
  },

  cancelbuttonbox: {
    background: "",
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px"
  },

  agreetextcolor: {
    color: "#ECFDF5",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textTransform:"capitalize" as "capitalize"
  },
  donetextcolor: {
    color: "#ECFDF5",
    textTransform:"capitalize" as "capitalize",
    lineHeight: "28px",
    fontSize: "20px",
    fontWeight: 400,
  },

  canceltextcolor: {
    color: "#1D6FB8",
    textTransform:"capitalize" as "capitalize",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },

}

// Customizable Area End
