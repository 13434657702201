export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteImgForm = require("../assets/delete.png");
export const cameraImage = require("../assets/image_camera.png");
export const warning = require("../assets/warning.png");
export const photoUploadIcon = require("../assets/upload.png");
export const photoDeleteIcon = require("../assets/delete_outline_24px(2).png");
export const photoFace = require("../assets/Face.png");
export const sidebarOne = require("../assets/img_sidebar_one.png");
export const sidebarTwo = require("../assets/img_sidebar_two.png");
export const sidebarThree = require("../assets/img_sidebar_three.png");
export const sidebarFour = require("../assets/img_sidebar_four.png");
export const sidebarFive = require("../assets/img_sidebar_five.png");
export const uploadimage= require ("../assets/upload1.png")
export const arrowdown = require("../assets/arrowdown.png")
export const edit = require("../assets/edit.png")