// Customizable Area Start
import React from "react";
import UpcomingRidesController, { Props } from "./UpcomingRidesController.web";
import {
    Typography,
    Box,
    styled,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,Popover,Paper, MenuList,Button,
    Dialog,
    DialogContent,DialogActions
} from "@mui/material"
import { edit, deleteicon,deleteImg,danger } from "../assets";
import { configJSON } from "../ProjectTemplatesController";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Customizable Area End

// Customizable Area Start
const Upcomingridesstatsbox = styled(Box)({
    width: "70%",
    "@media (max-width: 696px)": {
        overflowX: "auto",
        width: "90%",
    },

});

const Editcancelbox = styled(Box)({
    display: "flex",
    gap: "10px",
    borderRadius: "8px",
    border: "1px solid green",
    // top: "0",
    position: "absolute" as "absolute",
    height: "83px",
    left: "103%",
    paddingTop: "12px",
    width: "163px",
    flexDirection: "column" as "column",
    background: "#FFFFFF",
    // border: "1px solid #F5F5F5",
    "@media (max-width: 830px)": {
        left: "85%",
        top: '10px'
    },
    "@media (max-width: 730px)": {
        left: "70%",
        top: '10px'
    },
    "@media (max-width: 630px)": {
        top: "10px",
        left: "70%",
    },

});



const StyledTable = styled(Table)({
    borderCollapse: "separate",
    borderSpacing: "0 18px",
    width: "100%",
});


const RowContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    borderLeft: "4px solid #87C122",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    width: "100%"
});
const StyledTableCell = styled(TableCell)({
    border: "none",
    padding: "30px",
    whiteSpace: "nowrap",

});

const StyledButton2 = styled(Button)({
    display: "flex",
    alignItems: "center",
    width: "100%", // Full width for better click area
    justifyContent: "flex-start", // Align icon and text to the left
    padding: "10px",
    fontSize: "18px",
    fontWeight: 400,
    paddingLeft:'10%',
    textTransform: "none",
    color:'#0F172A'
  });
// Customizable Area End

export default class UpcomingRides extends UpcomingRidesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Upcomingridesstatsbox>
                <StyledTable sx={{ width: "100%", minWidth: 600 }}>
                    <TableBody>
                        {this.state.upcomingridesdata.length > 0 ? (
                            <>
                                {this.state.upcomingridesdata.map((ride: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            borderBottom: "none",
                                            padding: 0,
                                            margin: 0,
                                            position: "relative"
                                        }}
                                    >
                                        <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
                                            <RowContainer
                                                sx={{
                                                    display: "flex",
                                                    padding: "15px",
                                                    width: "100%",
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                {/* Date and Time */}
                                                <Box sx={{ flex: "1 1 100px" }}>
                                                    <Typography sx={webstyle.headingtext}>
                                                        {this.formatDate(ride.attributes.date)} {this.formatTime(ride.attributes.time)}
                                                    </Typography>
                                                </Box>

                                                {/* From Address */}
                                                <Box sx={{ flex: "1 1 150px", paddingLeft: "15px", paddingRight: "15px" }}>
                                                    <Typography sx={webstyle.headingtext}>{configJSON.from}</Typography>
                                                    <Typography sx={webstyle.datatext}>{ride.attributes.start}</Typography>
                                                </Box>

                                                {/* To Address */}
                                                <Box sx={{ flex: "1 1 150px", paddingRight: "15px" }}>
                                                    <Typography sx={webstyle.headingtext}>{configJSON.to}</Typography>
                                                    <Typography sx={webstyle.datatext}>{ride.attributes.destination}</Typography>
                                                </Box>

                                                {/* Price */}
                                                <Box sx={{ flex: "1 1 80px" }}>
                                                    <Typography sx={webstyle.headingtext}>{configJSON.price}</Typography>
                                                    <Typography sx={webstyle.datatext}>{ride.attributes.price}</Typography>
                                                </Box>

                                                {/* Status and Icon */}
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            ride.attributes.status === "In Progress" || ride.attributes.status === "Scheduled"
                                                                ? "space-between"
                                                                : "center",
                                                        height: "100%",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Box sx={{ display: "flex", justifyContent: "center" }}>

                                                        <IconButton data-test-id={`navigationid-${index}`}
                                                            onClick={(event) => { this.navigateToRating(ride.attributes.status, ride.id, event) }}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Popover

anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  style={{ transform: 'translate(50px, -10px)' }} 
                                                        open={this.state.openEditDialog}
                                                        anchorEl={this.state.anchorEl2}
                                                        disablePortal
                                                        onClose={(e) => { this.onClose(e) }}
                                                    >

                                                        <Paper style={{
                                                            width: '150px',
                                                            borderRadius: '8px',
                                                        }}>
                                                            <MenuList>
                                                                <StyledButton2 onClick={(e) => this.onEdit(e)} startIcon={<img src={edit.default} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} data-test-id="edit-button" />}>
                                                                    Edit
                                                                </StyledButton2>
                                                                <StyledButton2 onClick={(e) => this.onCancel(e)} startIcon={<img src={deleteImg.default} alt="delete" style={{ width: "18px", height: "22px", marginRight: "8px" }} data-testid="cancel-button" />}>
                                                                    Cancel
                                                                </StyledButton2>
                                                            </MenuList>
                                                        </Paper>


                                                    </Popover>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            padding: "2px 2px",
                                                            marginTop: 2.5,
                                                            backgroundColor: this.getStatusStyles(ride.attributes.status).backgroundColor,
                                                            color: this.getStatusStyles(ride.attributes.status).color,
                                                            borderRadius: "8px",
                                                            fontSize: "0.75rem",

                                                        }}
                                                    >
                                                        {this.capitalizeFirstLetter(ride.attributes.status)}
                                                    </Typography>
                                                </Box>
                                            </RowContainer>
                                        </StyledTableCell>
                                    </TableRow>

                                ))}

                            </>
                        ) : <h1>No data found</h1>}

                    </TableBody>
                </StyledTable>
                <StyledDialog open={this.state.deletePopUp} onClose={(e) => { this.onCloseDeletePopup(e) }}>
                    <StyledDialogContent>
                        <img src={danger.default} alt="Delete Icon" />
                        <Typography variant="h6" gutterBottom style={{ paddingTop: '20px' }}>
                            Are you sure you want to cancel this ride?
                        </Typography>
                    </StyledDialogContent>
                    <ActionsContainer>
                        <StyledCancelButton onClick={(e) => { this.onCloseDeletePopup(e) }} data-test-id="CancelButton">No</StyledCancelButton>
                        <StyledDeleteButton onClick={this.onDelete}>Yes</StyledDeleteButton>
                    </ActionsContainer>
                </StyledDialog>
            </Upcomingridesstatsbox>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
      borderRadius: "16px", // Apply border radius to the dialog
    },
  });
  const StyledDialogContent = styled(DialogContent)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30vw",
    maxWidth: "500px",
    minWidth: "250px",
    textAlign: "center",
    boxSizing: "border-box",
    padding: "20px", 
  });
  
  const ActionsContainer = styled(DialogActions)`
    justify-content: center;
    padding-bottom: 16px;
    padding-Top:20px;
  `;
  const StyledCancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#E7E5E4",
    color: "#64748B",
    borderRadius: "10px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "100px",
    marginRight:'20px',
    textTransform: "none", // Remove uppercase text
  
    "&:hover": {
      backgroundColor: "#D6D3D1",
    },
  
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "6px 12px",
      minWidth: "80px",
    },
  }));
  
  // Styled Delete Button
  const StyledDeleteButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#DC2626",
    color: "#FFFFFF",
    borderRadius: "10px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "100px",
    textTransform: "none",
  
    "&:hover": {
      backgroundColor: "#B91C1C",
    },
  
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "6px 12px",
      minWidth: "80px",
    },
  }));
const webstyle = {

    headingtext: {
        fontWeight: 700,
        fontSize: "18px",
        color: "#0F172A",
        lineHeight: "26px",
        whiteSpace: "normal",
        wordBreak: "break-word",
    },

    expandicon: {
        width: "6%",
        display: "flex",
        justifyContent: "end",
        height: "26px"
    },

    edittext: {
        fontWeight: 400,
        fontSize: "16px",
        color: "##0F172A",
        lineHeight: "24px",
    },

    editcancelbox: {
        display: "flex",
        gap: "10px",
        borderRadius: "8px",
        top: "0",
        position: "absolute" as "absolute",
        height: "83px",
        left: "101%",
        paddingTop: "12px",
        width: "163px",
        flexDirection: "column" as "column",
        background: "#FFFFFF",
        border: "1px solid #F5F5F5"
    },

    editinsidebox: {
        cursor: "pointer",
        display: "flex",
        paddingLeft: "12px",
        gap: "8px"
    },

    datatext: {
        fontWeight: 400,
        fontSize: "18px",
        color: "#0F172A",
        lineHeight: "26px",
        whiteSpace: "normal",
        wordBreak: "break-word"
    },

    ucomingrideinfobox: {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "space-around"
    }
}
// Customizable Area End
